import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Спа-комплекс Serenity Springs
			</title>
			<meta name={"description"} content={"Побалуйте себе комплексним оздоровленням"} />
			<meta property={"og:title"} content={"Наші послуги | Спа-комплекс Serenity Springs"} />
			<meta property={"og:description"} content={"Побалуйте себе комплексним оздоровленням"} />
			<meta property={"og:image"} content={"https://pro.prismatongrup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://pro.prismatongrup.com/img/0234522.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://pro.prismatongrup.com/img/0234522.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://pro.prismatongrup.com/img/0234522.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://pro.prismatongrup.com/img/0234522.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://pro.prismatongrup.com/img/0234522.png"} />
			<meta name={"msapplication-TileImage"} content={"https://pro.prismatongrup.com/img/0234522.png"} />
			<meta name={"msapplication-TileColor"} content={"https://pro.prismatongrup.com/img/0234522.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-primary"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Ознайомтеся з нашими послугами
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					У спа-комплексі Serenity Springs ми пропонуємо широкий спектр послуг, покликаних задовольнити всі ваші потреби, забезпечуючи цілісний підхід до оздоровлення. Наші найсучасніші засоби та досвідчені терапевти створюють середовище, де ви можете повністю зануритися в релаксацію та омолодження.
				</Text>
				<Link
					href="/contact-us"
					color="--dark"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-secondary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="8px"
				>
					Написати
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://pro.prismatongrup.com/img/3.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 120px 0" sm-padding="60px 0 60px 0" quarkly-title="Cards-8">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="flex-start"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 70px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					text-align="left"
					sm-font="normal 700 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					width="50%"
					lg-width="100%"
				>
					Послуги
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Лікувальні масажі: Наші масажні процедури призначені для зняття стресу, зменшення м'язової напруги та покращення кровообігу.
					<br/><br/>
   Шведський масаж: Класична техніка масажу, спрямована на розслаблення та зняття м'язової напруги.
					<br/><br/>
   Глибокий масаж тканин: Спрямований на глибокі шари м'язів і сполучних тканин для полегшення хронічного болю і скутості.
					<br/><br/>
   Масаж гарячими каменями: Поєднує традиційні техніки масажу з нагрітим камінням для посилення релаксації та зняття м'язової напруги.

							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Прогресивні процедури по догляду за шкірою: Наші процедури для обличчя призначені для вирішення конкретних проблем шкіри та покращення загального кольору обличчя.
					<br/><br/>
   Зволожуюча процедура для обличчя: Поповнює запаси вологи та відновлює баланс сухої, зневодненої шкіри.
					<br/><br/>
   Антивікова процедура для обличчя: Використовує передові методики та продукти для зменшення ознак старіння та сприяння молодому вигляду.
					<br/><br/>
   Лікування акне: Зосереджується на глибокому очищенні та очищенні, щоб зменшити кількість прищів і запобігти їх появі в майбутньому.

							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Процедури для тіла: Відчуйте омолодження всього тіла з нашим асортиментом процедур для тіла, розроблених для детоксикації та живлення шкіри.
					<br/><br/>
   Скраб для тіла: Відлущує та видаляє ороговілі клітини, роблячи шкіру гладенькою та сяючою.
					<br/><br/>
   Обгортання для тіла: Виводить токсини та зволожує шкіру, сприяючи здоровому та сяючому кольору обличчя.
					<br/><br/>
   Ароматерапія: Використовує ефірні олії для покращення розслаблення, настрою та загального самопочуття.

							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Холістичний велнес: Досягніть збалансованості розуму і тіла з нашими послугами холістичного велнесу.
					<br/><br/>
   Заняття йогою: Покращуйте гнучкість, силу та ясність розуму на наших заняттях з йоги під керівництвом інструктора.
					<br/><br/>
   Заняття з медитації: Розвивайте уважність та зменшуйте стрес за допомогою наших занять з медитації під керівництвом експертів.
					<br/><br/>
   Рефлексотерапія: Стимулює певні точки на стопах для покращення загального стану здоров'я та розслаблення.

							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
			margin="0px 0px 0px 0px"
			color="--darkL1"
			font="--headline2"
			lg-text-align="center"
			display="flex"
			align-items="flex-start"
		>
				Зв'яжіться з нами для отримання додаткової інформації
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					І це лише початок того, що може запропонувати спа-комплекс Serenity Springs. Щоб дізнатися про повний спектр наших послуг і спланувати свій ідеальний спа-день, запрошуємо вас зв'язатися з нами напряму. Наш привітний персонал готовий відповісти на будь-які ваші запитання та допомогти вам забронювати ідеальний спа-процедуру.
					<br/><br/>
Ми з нетерпінням чекаємо на вас у спа-комплексі Serenity Springs, де розпочнеться ваша оздоровча подорож. Пориньте у спокій та розкіш нашого спа-центру і дозвольте нам провести вас до стану повного розслаблення та омолодження.

				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://pro.prismatongrup.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://pro.prismatongrup.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});